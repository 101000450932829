import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebase';
import { Projet } from '../types/Projet';
import { signOut } from 'firebase/auth'; // Importer signOut

const Projets: React.FC = () => {
  const [projets, setProjets] = useState<Projet[]>([]);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate(); // Hook pour la navigation

  useEffect(() => {
    const fetchProjets = async () => {
      if (!user) {
        console.error("Utilisateur non authentifié");
        return;
      }

      try {
        const projetsQuery = query(collection(db, 'projets'), where('createdBy', '==', user.uid));
        const querySnapshot = await getDocs(projetsQuery);
        const projetsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Projet));

        setProjets(projetsData);
      } catch (fetchError) {
        console.error("Erreur lors de la récupération des projets:", fetchError);
      }
    };

    fetchProjets();
  }, [user]); // Re-fetch projects when user changes

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'projets', id));
      setProjets(projets.filter(projet => projet.id !== id));
    } catch (deleteError) {
      console.error("Erreur lors de la suppression du projet:", deleteError);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Rediriger l'utilisateur vers la page de connexion
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error.message}</div>;
  if (!user) return <div>Veuillez vous connecter pour voir vos projets</div>;

  return (
    <div>
      <h1>Liste des Projets</h1>
      <button onClick={handleLogout}>Se déconnecter</button> {/* Bouton de déconnexion */}
      <Link to="/projets/nouveauprojet">Créer un nouveau projet</Link>
      <ul>
        {projets.map(projet => (
          <li key={projet.id}>
            {projet.adresse} - {projet.date}
            <Link to={`/projets/modifier/${projet.id}`}>Modifier</Link>
            <Link to={`/projets/voir/${projet.id}`}>Voir</Link>
            <button onClick={() => handleDelete(projet.id!)}>Supprimer</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Projets;
