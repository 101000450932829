import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";
import logoSrc from '../logotransparentup.svg';
import logoSrcNws from '../logotransparentupnws.svg';
import ImageSnippet from '../Snippet-Image.png';
import CustomSnipper from '../Customizable-Image.png'

const Home: React.FC = () => {
  const navRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const navEl = menuButtonRef.current;
    const nav = navRef.current;

    const handleMenuClick = () => {
      if (nav) {
        nav.classList.toggle("active");
      }
    };

    if (navEl) {
      navEl.addEventListener("click", handleMenuClick);
    }

    return () => {
      if (navEl) {
        navEl.removeEventListener("click", handleMenuClick);
      }
    };
  }, []);

  return (
    <div>
      <nav className="mobile-menu" ref={navRef}>
        <ul>
          <li><a href="/login">Se Connecter</a></li>
          <li><a href="/login" className="product-link">Commencer &#8211; <span>C'est gratuit</span></a></li>
        </ul>
      </nav>
      <main>
        <section className="section-1">
          <div className="section-1-main">
            <nav>
              <div className="nav-logo" style={{ width: '10%' }}>
                <a href="/">
                  <img src={logoSrc} alt="Logo" style={{ width: '50%' }} />
                </a>
              </div>
              <div className="nav-links">
                <ul>
                  <li><a href="/login">Se Connecter</a></li>
                  <li><a href="/login" className="product-link">Commencer &#8211; <span>C'est gratuit</span></a></li>
                </ul>
              </div>
              <div className="nav-mobile-menu-btn">
                <img src="https://rvs-product-landing-page.vercel.app/Assets/Hamburger-Menu.svg" alt="Menu Button" ref={menuButtonRef} />
              </div>
            </nav>
            <div className="section-content">
              <h1 className="section-1-title">Le Futur Des Etats Des Lieux - InspectIA</h1>
              <p className="section-1-desc">Avec InspectIA, créez des états des lieux propulsés par l'IA en quelques secondes depuis n'importe où.</p>
              <a href="#" className="section-1-link product-link">Commencer &#8211; <span>C'est gratuit</span></a>
              <p className="section-1-alt-txt">Aucune carte de crédit requise.</p>
              <img src={ImageSnippet} alt="Snippet Example" className="section-1-img" />
            </div>
          </div>
        </section>
        <section className="section-2">
          <div className="section-2-main">
            <div className="section-2-1">
              <h1 className="section-2-1-title">Un outil d'état des lieux entièrement repensé</h1>
              <p className="section-2-1-desc">Réalisez un état des lieux dans un temps record et avec une précision jamais vu grâce à l'intelligence artificielle. Exportez ensuite ce rapport en PDF pour le diffuser à toutes les personnes concernées.</p>
              <a href="#" className="section-2-1-link product-link">Commencer &#8211; C'est gratuit</a>
            </div>
            <div className="section-2-2">
              <img src={CustomSnipper} alt="Customizable Editor" className="section-2-2-img" />
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-main">
          <div className="footer-content">
            <div className="footer-logo" style={{ width: '10%' }}>
              <a href="/">
                <img src={logoSrcNws} alt="Footer Logo" style={{ width: '50%', backgroundColor: "#2D3748",  borderRadius: "10px"}}/>
              </a>
            </div>
            <div className="footer-links">
              <ul>
                <li><a href="#">Termes et Conditions</a></li>
                <li><a href="#">Politique de Confidentialité</a></li>
              </ul>
            </div>
          </div>
          <div className="footer-text">
            <p>Créé par - <a href="https://n-w-s.fr/"><span>NWS</span></a></p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
