import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import Home from './pages/Home';
import Login from './pages/Login';
import Projets from './pages/Projets';
import NouveauProjet from './pages/NouveauProjet';
import ModifierProjet from './pages/ModifierProjet';
import VoirProjet from './pages/VoirProjet';

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/projets" 
          element={user ? <Projets /> : <Navigate to="/login" replace />} 
        />
        <Route 
          path="/projets/nouveauprojet" 
          element={user ? <NouveauProjet /> : <Navigate to="/login" replace />} 
        />
        <Route 
          path="/projets/modifier/:id" 
          element={user ? <ModifierProjet /> : <Navigate to="/login" replace />} 
        />
        <Route 
          path="/projets/voir/:id" 
          element={user ? <VoirProjet /> : <Navigate to="/login" replace />} 
        />
      </Routes>
    </Router>
  );
};

export default App;