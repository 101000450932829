import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Projet } from '../types/Projet';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import '../VoirProjet.css';

const VoirProjet: React.FC = () => {
  const [projet, setProjet] = useState<Projet | null>(null);
  const { id } = useParams<{ id: string }>();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchProjet = async () => {
      if (!id) return;
      const docRef = doc(db, 'projets', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProjet({ id: docSnap.id, ...docSnap.data() as Omit<Projet, 'id'> });
      }
    };
    fetchProjet();
  }, [id]);

  const generatePDF = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; // Largeur en mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('projet.pdf');
    }
  };

  if (!projet) return <div>Chargement...</div>;

  return (
    <div>
      <div ref={contentRef}>
        <h1>Détails du Projet</h1>
        <p><strong>Adresse:</strong> {projet.adresse}</p>
        <p><strong>Type de logement:</strong> {projet.typeLogement}</p>
        <p><strong>Date:</strong> {projet.date}</p>
        <p><strong>Type d'état des lieux:</strong> {projet.typeEtatDesLieux}</p>
        <h2>Pièces:</h2>
        {projet.pieces.map((piece, index) => (
          <div key={index}>
            <h3>{piece.nom}</h3>
            <p><strong>Annotations:</strong> {piece.annotations}</p>
            {piece.photos.length > 0 && (
              <div>
                <p><strong>Photos:</strong></p>
                {piece.photos.map((photo, photoIndex) => (
                  <img key={photoIndex} src={photo} alt={`Photo ${photoIndex + 1} de ${piece.nom}`} style={{maxWidth: '200px', margin: '5px'}} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <button onClick={generatePDF}>
        Générer PDF
      </button>
      <Link to="/projets">
        Retour à la liste des projets
      </Link>
    </div>
  );
};

export default VoirProjet;
