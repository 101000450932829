// src/pages/ModifierProjet.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Projet, Piece } from '../types/Projet';

const ModifierProjet: React.FC = () => {
  const [projet, setProjet] = useState<Projet | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjet = async () => {
      if (!id) return;
      const docRef = doc(db, 'projets', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProjet({ id: docSnap.id, ...docSnap.data() as Omit<Projet, 'id'> });
      }
    };
    fetchProjet();
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setProjet(prev => prev ? { ...prev, [e.target.name]: e.target.value } : null);
  };

  const handlePieceChange = (index: number, field: keyof Piece, value: string) => {
    setProjet(prev => {
      if (!prev) return null;
      const newPieces = [...prev.pieces];
      newPieces[index] = { ...newPieces[index], [field]: value };
      return { ...prev, pieces: newPieces };
    });
  };

  const handleAddPiece = () => {
    setProjet(prev => prev ? { ...prev, pieces: [...prev.pieces, { nom: '', annotations: '', photos: [] }] } : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (projet && projet.id) {
      const { id, ...projetWithoutId } = projet;
      await updateDoc(doc(db, 'projets', id), projetWithoutId);
      navigate('/projets');
    }
  };

  if (!projet) return <div>Chargement...</div>;

  return (
    <form onSubmit={handleSubmit}>
      <input name="adresse" value={projet.adresse} onChange={handleChange} placeholder="Adresse" required />
      <select name="typeLogement" value={projet.typeLogement} onChange={handleChange}>
        <option value="appartement">Appartement</option>
        <option value="maison">Maison</option>
      </select>
      <input type="date" name="date" value={projet.date} onChange={handleChange} required />
      <select name="typeEtatDesLieux" value={projet.typeEtatDesLieux} onChange={handleChange}>
        <option value="entree">Entrée</option>
        <option value="sortie">Sortie</option>
      </select>
      {projet.pieces.map((piece, index) => (
        <div key={index}>
          <input 
            value={piece.nom} 
            onChange={(e) => handlePieceChange(index, 'nom', e.target.value)} 
            placeholder="Nom de la pièce" 
          />
          <textarea 
            value={piece.annotations} 
            onChange={(e) => handlePieceChange(index, 'annotations', e.target.value)} 
            placeholder="Annotations" 
          />
          {/* Ici, vous pouvez ajouter un composant pour l'upload de photos */}
        </div>
      ))}
      <button type="button" onClick={handleAddPiece}>Ajouter une pièce</button>
      <button type="submit">Enregistrer les modifications</button>
    </form>
  );
};

export default ModifierProjet;