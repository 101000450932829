import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth, storage } from '../firebase'; // Assurez-vous que storage est importé
import { Projet, Piece } from '../types/Projet';
import Component from './Component';
import Adresse from './Adresse';

const NouveauProjet: React.FC = () => {
  const [projet, setProjet] = useState<Projet>({
    adresse: '',
    typeLogement: 'appartement',
    date: new Date().toISOString().split('T')[0],
    typeEtatDesLieux: 'entree',
    pieces: [],
    createdBy: '',
  });
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setProjet({ ...projet, [e.target.name]: e.target.value });
  };

  const handlePieceChange = (index: number, field: keyof Piece, value: string | File[]) => {
    const newPieces = [...projet.pieces];
    newPieces[index] = { ...newPieces[index], [field]: value };
    setProjet({ ...projet, pieces: newPieces });
  };

  const handleAddPiece = () => {
    setProjet({ ...projet, pieces: [...projet.pieces, { nom: '', annotations: '', photos: [] }] });
  };

  const handlePhotoUpload = (index: number, files: FileList | null) => {
    if (files) {
      const photoArray = Array.from(files);
      handlePieceChange(index, 'photos', photoArray);
    }
  };

  const uploadPhotos = async (photos: File[], projetId: string) => {
    const uploadedUrls: string[] = [];

    if (!user) {
      console.error("Utilisateur non authentifié");
      throw new Error("Utilisateur non authentifié");
    }

    try {
      for (const photo of photos) {
        const photoRef = ref(storage, `projets/${projetId}/${photo.name}`);
        await uploadBytes(photoRef, photo);
        const downloadURL = await getDownloadURL(photoRef);
        uploadedUrls.push(downloadURL);
      }
    } catch (uploadError) {
      console.error("Erreur lors du téléchargement des photos:", uploadError);
      throw uploadError; // Propager l'erreur pour la gestion ultérieure
    }

    return uploadedUrls;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      console.error("Utilisateur non authentifié");
      return;
    }
    
    try {
      // Inclure createdBy directement lors de l'ajout du document
      const newProjetData: Projet = {
        ...projet,
        pieces: [], // Assurez-vous que pieces est initialisé correctement
        createdBy: user.uid, // Ajout du champ createdBy
      };
  
      // Créer le projet avec les données complètes
      const newProjetRef = await addDoc(collection(db, 'projets'), newProjetData);
      const projetId = newProjetRef.id;
  
      const updatedPieces = await Promise.all(projet.pieces.map(async (piece) => {
        if (Array.isArray(piece.photos) && piece.photos.length > 0 && typeof piece.photos[0] !== 'string') {
          const photoUrls = await uploadPhotos(piece.photos as unknown as File[], projetId);
          return { ...piece, photos: photoUrls };
        } else {
          return piece;
        }
      }));
  
      // Mettre à jour le document avec les pièces mises à jour
      await updateDoc(doc(db, 'projets', projetId), { pieces: updatedPieces });
      
      navigate('/projets');
    } catch (error) {
      console.error("Erreur lors de l'ajout du projet:", error);
    }
  };
  

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur: {error.message}</div>;
  if (!user) return <div>Veuillez vous connecter pour créer un projet</div>;

  return (
    <div id="laDiv">
    {/* <form onSubmit={handleSubmit} style={{width: "100vw"}}> */}
      <Adresse />
      {/* <input name="adresse" value={projet.adresse} onChange={handleChange} placeholder="Adresse" required />
      <select name="typeLogement" value={projet.typeLogement} onChange={handleChange}>
        <option value="appartement">Appartement</option>
        <option value="maison">Maison</option>
      </select>
      <input type="date" name="date" value={projet.date} onChange={handleChange} required />
      <select name="typeEtatDesLieux" value={projet.typeEtatDesLieux} onChange={handleChange}>
        <option value="entree">Entrée</option>
        <option value="sortie">Sortie</option>
      </select>
      {projet.pieces.map((piece, index) => (
        <div key={index}>
          <input 
            value={piece.nom} 
            onChange={(e) => handlePieceChange(index, 'nom', e.target.value)} 
            placeholder="Nom de la pièce" 
          />
          <textarea 
            value={piece.annotations} 
            onChange={(e) => handlePieceChange(index, 'annotations', e.target.value)} 
            placeholder="Annotations" 
          />
          <input 
            type="file" 
            multiple 
            accept="image/*" 
            onChange={(e) => handlePhotoUpload(index, e.target.files)} 
          />
        </div>
      ))}
      <button type="button" onClick={handleAddPiece}>Ajouter une pièce</button> */}
      <Component />
      <button type="submit" style={{"float": "right"}}>Enregistrer</button>
    {/* </form> */}
    </div>
  );
};

export default NouveauProjet;
