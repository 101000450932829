import { useState } from "react";
import './Component.css'; // Fichier CSS externe pour les styles

export default function Component() {
  const [parts, setParts] = useState([{ id: 1, name: "Pièce 1", annotations: "" }]);

  const addPart = () => {
    const nextPartNumber = parts.length + 1;
    setParts([...parts, { id: nextPartNumber, name: `Pièce ${nextPartNumber}`, annotations: "" }]);
  };

  const updatePartName = (id: number, newName: string | null) => {
    if (newName) {
      setParts(parts.map((part) => (part.id === id ? { ...part, name: newName } : part)));
    }
  };

  return (
    <div className="container">
      <div className="grid">
        {parts.map((part) => (
          <div key={part.id} className="card">
            <div className="card-header">
              <div className="flex-space-between">
                <h2 className="card-title">{part.name}</h2>
                <button
                  className="icon-button"
                  onClick={() => updatePartName(part.id, prompt("Changer le nom de la pièce :", part.name))}
                >
                  <PenIcon className="icon iconpen" />
                </button>
              </div>
            </div>
            <div className="card-content">
              <label htmlFor={`part-${part.id}-annotations`}>Annotations</label>
              <textarea
                id={`part-${part.id}-annotations`}
                value={part.annotations}
                onChange={(e) =>
                  setParts(parts.map((p) => (p.id === part.id ? { ...p, annotations: e.target.value } : p)))
                }
              />
              <div className="flex-end">
                <button style={{"display":"inline-flex","paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"1rem","paddingRight":"1rem","justifyContent":"center","alignItems":"center","borderRadius":"0.375rem","borderWidth":"1px","height":"2.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"whiteSpace":"nowrap","transitionProperty":"color, background-color, border-color, text-decoration-color, fill, stroke","transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms", "gap":"5px"}}>
                  <CameraIcon className="icon" />
                  Prendre une photo
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="add-part">
        <button style={{"display":"inline-flex","paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"1rem","paddingRight":"1rem","justifyContent":"center","alignItems":"center","borderRadius":"0.375rem","height":"2.5rem","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"whiteSpace":"nowrap","transitionProperty":"color, background-color, border-color, text-decoration-color, fill, stroke","transitionTimingFunction":"cubic-bezier(0.4, 0, 0.2, 1)","transitionDuration":"300ms"}} onClick={addPart}>
          <PlusIcon className="icon" />
          Ajouter une pièce ({parts.length + 1})
        </button>
      </div>
    </div>
  );
}

function CameraIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" />
      <circle cx="12" cy="13" r="3" />
    </svg>
  );
}

function PenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
    </svg>
  );
}

function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
}
