// src/pages/Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, signInWithGoogle } from '../firebase'; // Importer signInWithGoogle
import "./Login.css";

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/projets');
    } catch (error) {
      console.error('Erreur de connexion:', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      navigate('/projets'); // Redirige après connexion avec Google
    } catch (error) {
      console.error('Erreur de connexion avec Google:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <h1>Se connecter</h1>
        <div className="social-media">
          <p onClick={handleGoogleLogin}><i className="fab fa-google"></i></p>
        </div>
        <p className="choose-email">ou utiliser mon adresse e-mail :</p>
        
        <div className="inputs">
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
          <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} required/>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button type="submit">Se connecter</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
