import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD88mzI7EgEduT5ebPJXWxkgPTtatlCHFI",
    authDomain: "app-gestion-immobiliere.firebaseapp.com",
    projectId: "app-gestion-immobiliere",
    storageBucket: "app-gestion-immobiliere.appspot.com",
    messagingSenderId: "109305791668",
    appId: "1:109305791668:web:a4b25059e78d7bc1fb43e1"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
    return signInWithPopup(auth, googleProvider);
};
export const logOut = () => {
    return signOut(auth);
};
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;