import React from 'react';
import './Adresse.css'; // Fichier CSS externe pour les styles

export default function Adresse() {
  return (
    <div className="card max-w-md">
      <div className="card-header">
        <h2 className="card-title">Nouvel Etat Des Lieux</h2>
        <p className="card-description">Entrez l'adresse ci-dessous.</p>
      </div>
      <div className="card-content">
        <form className="form-grid" style={{"paddingLeft": "0px","paddingTop": "0px"}}>
          <div className="input-group">
            <label htmlFor="address">Adresse</label>
            <input
              id="address"
              type="text"
              placeholder="1 Rue de Paris"
              required
              pattern="^[a-zA-Z0-9s,.'-]{3,}$"
              title="Veuillez entrer une adresse postale valide"
            />
          </div>
          <div className="input-group">
            <div className="input-group">
              <label htmlFor="city">Ville</label>
              <input
                id="city"
                type="text"
                placeholder="Paris"
                required
                pattern="^[a-zA-Zs]+$"
                title="Veuillez entrer un nom de ville valide"
              />
            </div>
          </div>
        <div className="input-group">
            <label htmlFor="zip">Code Postal</label>
            <input
            id="zip"
            type="text"
            placeholder="77000"
            required
            pattern="\d{5}"
            title="Veuillez saisir un code postal valide à 5 chiffres"
            />
        </div>
        <div className="input-group">
            <label htmlFor="country">Pays</label>
            <input
            id="pays"
            type="text"
            placeholder="France"
            required
            />
        </div>
        <select className="typeEtatDesLieux">
            <option value="entree">Entrée</option>
            <option value="sortie">Sortie</option>
        </select>
        <select className="typeLogement">
            <option value="appartement">Appartement</option>
            <option value="maison">Maison</option>
        </select>
        <input className="dateChoice" type="date" name="date" required />
        </form>
      </div>
    </div>
  );
}
